import surrounding1 from "./1-7.webp";
import surrounding2 from "./2-6.webp";
import surrounding3 from "./20.webp";
import surrounding4 from "./3-7.webp";
import surrounding5 from "./4-5.webp";
import surrounding6 from "./5-5.webp";
import surrounding7 from "./5.webp";
import surrounding8 from "./6-5.webp";

const surroundingimg = [
  surrounding1,
  surrounding2,
  surrounding3,
  surrounding4,
  surrounding5,
  surrounding6,
  surrounding7,
  surrounding8,
];

export default surroundingimg;
