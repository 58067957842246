import sadyagrya1 from "./13-1.webp";
import sadyagrya2 from "./14-3.webp";
import sadyagrya3 from "./15-1.webp";
import sadyagrya4 from "./16-2.webp";
import sadyagrya5 from "./17-2.webp";
import sadyagrya6 from "./18-2.webp";
import sadyagrya7 from "./19-1.webp";
import sadyagrya8 from "./20-2.webp";
import sadyagrya9 from "./Screenshot_1.webp";

const sadyagryaimg = [
  sadyagrya1,
  sadyagrya2,
  sadyagrya3,
  sadyagrya4,
  sadyagrya5,
  sadyagrya6,
  sadyagrya7,
  sadyagrya8,
  sadyagrya9,
];

export default sadyagryaimg;
