import fasilitas1 from "./1-2.webp";
import fasilitas2 from "./2-3.webp";
import fasilitas3 from "./3.webp";
import fasilitas4 from "./4-1.webp";
import fasilitas5 from "./5-2.webp";
import fasilitas6 from "./6-3 (1).webp";
import fasilitas7 from "./7-1.webp";
import fasilitas8 from "./8-1.webp";
import fasilitas9 from "./9-3.webp";

const fasilitasimg = [
  fasilitas1,
  fasilitas2,
  fasilitas3,
  fasilitas4,
  fasilitas5,
  fasilitas6,
  fasilitas7,
  fasilitas8,
  fasilitas9,
];

export default fasilitasimg;
