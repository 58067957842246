import React from "react";
import "./About.scss";
import logo from "../../Media/LOGO-POPARK-PNG-2048x1184-1-1536x888.png-1.webp";

const About = () => {
  return (
    <div className="aboutcont">
      <div className="aboutcenter">
        <div className="aboutlogo">
          <img alt="podomorobandg" src={logo} className="logo" />
        </div>
        <div className="abouttitle">Podomoro Park Bandung</div>
        <div className="aboutdes">
          Podomoro Park Bandung adalah sebuah kawasan hunian yang terletak di
          Bojongsoang, Kabupaten Bandung, Jawa Barat, Indonesia. Podomoro Park
          Bandung menawarkan hunian yang mengutamakan keselarasan dengan alam
          dan memberikan ketenangan dan kenyamanan layaknya tinggal dalam
          kawasan resort premium.
        </div>
        <hr />
      </div>
    </div>
  );
};

export default About;
