import React from "react";
import "./Surrounding.scss";
import Slider from "react-slick";
import surroundingimg from "../../Media/Surrounding";

const Surrounding = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
  };

  return (
    <div className="surcont">
      <div className="surcenter">
        <div id="kawasan" className="surtitle">
          SURROUNDING AREA
        </div>
        <div className="slider">
          <Slider {...settings}>
            {surroundingimg.map((item, index) => (
              <img
                className="cluster-image"
                key={index}
                src={item}
                alt={`galery ${index + 1}`}
              />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Surrounding;
