import React from "react";
import "./Hero.scss";
import heroimg from "../../Media/Hero";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Hero = () => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyLoad: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <div className="herocontainer">
      <div className="slider">
        <Slider {...settings}>
          {heroimg.map((item, index) => (
            <img
              className="podomoroimg"
              key={index}
              src={item}
              alt={`galery ${index + 1}`}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Hero;
