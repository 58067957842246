import React from "react";
import "./studenthouse.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import highlight from "../../../Media/highlight/highlight";
import Card from "../../../Component/Card/Card";
import data from "../data.json";
import studenthouse from "../../../Media/Product/studenthouse/studenthouse.js";

const Studenthouse = () => {
  const imageMap = {
    studenthouse,
  };
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div id="studenthouse" className="studenthouse">
      <div className="container-studenthouse">
        <div className="header-studenthouse">
          <div className="judul-studenthouse">
            Student House At Naragriya Cluster
          </div>
          <div className="desk-studenthouse">
            Student House at Naragriya Cluster Podomoro Park Bandung adalah
            sebuah jenis properti yang dirancang khusus untuk siswa, terletak di
            kawasan Naragriya Cluster di Podomoro Park, Bandung. Berlokasi
            strategis di jantung pendidikan Bandung selatan, properti ini sangat
            ideal untuk siswa yang bersekolah di sekitar area tersebut, seperti
            Binus University dan beberapa universitas/sekolah terkenal lainnya.
          </div>
        </div>
        <div className="container-carousel">
          <div className="carousel-fas">
            <Slider {...settings}>
              {highlight.map((image, index) => (
                <div className="parent">
                  <img
                    className="image-logo"
                    key={index}
                    src={image}
                    alt="foto logo"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <div className="cardsec">
        {data.sh.map((card, index) => {
          // Determine the logo dynamically

          return (
            <Card
              key={index}
              carousel={imageMap[card.imagesKey]}
              cardtitle={card.cardtitle} // Dynamically import logo images
              title={card.title}
              harga={card.harga}
              price={card.price}
              subprice={card.subprice}
              details={card.details}
              whatsappLink={card.whatsappLink}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Studenthouse;
