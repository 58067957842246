import wangsariya1 from "./A1C2C2.webp";
import wangsariya2 from "./23.webp";
import wangsariya3 from "./24.webp";
import wangsariya4 from "./25.webp";
import wangsariya5 from "./26.webp";
import wangsariya6 from "./27.webp";
import wangsariya7 from "./S__2293872.jpg";
import wangsariya8 from "./Screenshot_2.webp";

const wangsariyaimg = [
  wangsariya1,
  wangsariya2,
  wangsariya3,
  wangsariya4,
  wangsariya5,
  wangsariya6,
  wangsariya7,
  wangsariya8,
];

export default wangsariyaimg;
