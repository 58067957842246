import React from "react";
import map from "../../Media/map.png";
import "./Lokasi.scss";
const Lokasi = () => {
  const lokasipoint = [
    "Living Plaza Mall",
    "Primaya Hospital",
    "Al Azhar International School",
    "Resto Talaga Sampireun",
    "Pasar bersih modern",
    "Clubhouse di Cluster",
    "Danau buatan (sepanjang 1km)",
    "Mc Donald Podomoro Park",
    "Starbuck",
    "Indomaret Drive Thru 24 jam",
    "Kuliner District",
    "10 Menit Akses Tol Buah Batu",
    "Di lewati bis Trans Pasundan",
    "20 Menit ke Stasiun Kereta Cepat bdg -  jkt Tegal Luar",
  ];

  return (
    <div className="lokasicont">
      <div id="lokasi" className="lokasicenter">
        <div className="lokasi">
          <img src={map} alt="mappodomor" className="map" />
        </div>
        <div className="lokasititle">LOKASI</div>
        <div className="lokasipoint">
          <ul>
            {lokasipoint.map((item, index) => (
              <li className="point" key={index}>
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Lokasi;
