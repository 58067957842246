import React from "react";
import "./Product.scss";
import data from "./data.json";
import Card from "../../Component/Card/Card";
import patragriyaimg from "../../Media/Product/Patragriya";
import wangsariyaimg from "../../Media/Product/Wangsariya";
import sadyagryaimg from "../../Media/Product/Sadyagrya";
import fashagriyaimg from "../../Media/Product/Fashagriya";
import rukoimg from "../../Media/Product/Ruko La Plaza";
import brahmapuriimg from "../../Media/Product/Brahmapuri";

const Product = () => {
  const imageMap = {
    patragriyaimg,
    wangsariyaimg,
    sadyagryaimg,
    fashagriyaimg,
    rukoimg,
    brahmapuriimg,
  };

  return (
    <div className="productcont">
      <div className="productcenter">
        <div id="rumah" className="titleproduct">
          Our Home
        </div>
        <div className="cardsection">
          {data.cards.map((card, index) => {
            // Determine the logo dynamically

            return (
              <Card
                key={index}
                carousel={imageMap[card.imagesKey]}
                cardtitle={card.cardtitle} // Dynamically import logo images
                title={card.title}
                harga={card.harga}
                price={card.price}
                subprice={card.subprice}
                details={card.details}
                whatsappLink={card.whatsappLink}
              />
            );
          })}
        </div>
        <div id="ruko" className="titleproduct">
          Our Ruko
        </div>
        <div className="cardsection">
          {data.cards1.map((card1, index) => {
            // Determine the logo dynamically

            return (
              <Card
                key={index}
                carousel={imageMap[card1.imagesKey]}
                cardtitle={card1.cardtitle} // Dynamically import logo images
                title={card1.title}
                harga={card1.harga}
                price={card1.price}
                subprice={card1.subprice}
                details={card1.details}
                whatsappLink={card1.whatsappLink}
              />
            );
          })}
        </div>
        <hr />
      </div>
    </div>
  );
};

export default Product;
