import React from "react";
import "./Fasilitas.scss";
import Slider from "react-slick";
import fasilitasimg from "../../Media/Fasilitas";
const Fasilitas = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
  };
  return (
    <div className="fasilitascont">
      <div className="fasilitascenter">
        <div className="title">Fasilitas Premium</div>
        <div className="slider">
          <Slider {...settings}>
            {fasilitasimg.map((lt6, index) => (
              <img
                className="card-image"
                key={index}
                src={lt6}
                alt={`Launching ${index + 1}`}
              ></img>
            ))}
          </Slider>
        </div>
        <hr />
      </div>
    </div>
  );
};

export default Fasilitas;
