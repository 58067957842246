import fashagriya1 from "./21.webp";
import fashagriya2 from "./22.webp";
import fashagriya3 from "./23.webp";
import fashagriya4 from "./24.webp";
import fashagriya5 from "./25.webp";
import fashagriya6 from "./26.webp";
import fashagriya7 from "./27.webp";
import fashagriya8 from "./28.webp";
import fashagriya9 from "./Screenshot_1-1.webp";

const fashagriyaimg = [
  fashagriya1,
  fashagriya2,
  fashagriya3,
  fashagriya4,
  fashagriya5,
  fashagriya6,
  fashagriya7,
  fashagriya8,
  fashagriya9,
];

export default fashagriyaimg;
