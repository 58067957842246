import React from "react";
import Navbar from "../../Section/Navbar/Navbar";
import Hero from "../../Section/Hero/Hero";
import About from "../../Section/About/About";
import Promo from "../../Section/Promo/Promo";
import Fasilitas from "../../Section/Fasilitas/Fasilitas";
import New from "../../Section/New/New";
import Product from "../../Section/Product/Product";
import Surrounding from "../../Section/Surrounding/Surrounding";
import Lokasi from "../../Section/Lokasi/Lokasi";
import Footer from "../../Section/Footer/Footer";
import "./Home.scss";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import ScrollToHashElement from "./ScrollToHashElement";
const Home = () => {
  return (
    <div className="app">
      <ScrollToHashElement />
      <Navbar />
      <Hero />
      <About />
      <Promo />
      <Fasilitas />
      <New />
      <Product />
      <Surrounding />
      <Lokasi />
      <Footer />
      <a
        rel="noreferrer"
        target="_blank"
        href="https://api.whatsapp.com/send?phone=6282116601804&text=Halo%20Kevin%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Lokasi)%20(https://www.podomoropark-bdg.com/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0"
      >
        <FloatingWhatsApp />
      </a>
    </div>
  );
};

export default Home;
