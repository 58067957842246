import brahmapuri1 from "./1-6.webp";
import brahmapuri2 from "./2-5.webp";
import brahmapuri3 from "./3-6.webp";
import brahmapuri4 from "./4-4.webp";
import brahmapuri5 from "./5-4.webp";
import brahmapuri6 from "./6-4.webp";
import brahmapuri7 from "./7-4.webp";
import brahmapuri8 from "./8-3.webp";
import brahmapuri9 from "./9-5.webp";
import brahmapuri10 from "./10-4.webp";
import brahmapuri11 from "./11-4.webp";
import brahmapuri12 from "./12-2.webp";

const brahmapuriimg = [
  brahmapuri1,
  brahmapuri2,
  brahmapuri3,
  brahmapuri4,
  brahmapuri5,
  brahmapuri6,
  brahmapuri7,
  brahmapuri8,
  brahmapuri9,
  brahmapuri10,
  brahmapuri11,
  brahmapuri12,
];

export default brahmapuriimg;
