import Highlight1 from "./highlight (1).webp";
import Highlight2 from "./highlight (2).webp";
import Highlight3 from "./highlight (3).webp";
import Highlight4 from "./highlight (4).webp";
import Highlight5 from "./highlight (5).webp";
import Highlight6 from "./highlight (6).webp";
import Highlight7 from "./highlight (7).webp";
import Highlight8 from "./highlight (8).webp";
import Highlight9 from "./highlight (9).webp";
import Highlight10 from "./highlight (10).webp";
import Highlight11 from "./highlight (11).webp";
import Highlight12 from "./highlight (12).webp";

const highlight = [
  Highlight1,
  Highlight2,
  Highlight3,
  Highlight4,
  Highlight5,
  Highlight6,
  Highlight7,
  Highlight8,
  Highlight9,
  Highlight10,
  Highlight11,
  Highlight12,
];

export default highlight;
